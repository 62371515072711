.comment {
  overflow-y: scroll;
  height: 200px;
  display: flex;
  flex-direction: column-reverse;
}
.comment::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 12px;
}

.comment::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.644);
}
