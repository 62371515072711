.MuiContainer-maxWidthLg {
  max-width: 100% !important;
}

.PrivateTabIndicator-colorPrimary-24, .PrivateTabIndicator-colorPrimary-25 {
  background-color: white !important;
}

/* .MuiSvgIcon-root, .TodayButtonBase-button-29:first-child, .MuiButton-label, .MuiTableCell-body {
  color: #1F299C;
} */

.MuiTabs-flexContainer {
  display: unset !important;
}