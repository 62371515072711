.mainContainer {
  max-height: 80vh;
  overflow-y: auto;
}

.mainContainer::-webkit-scrollbar {
  display: none;
}

.container {
  margin: 1em 20px;
}

.header {
  font-size: 22px;
  font-weight: bold;
}

.mapContainer {
  margin-top: 1.5rem;
  width: 100%;
  height: 300px;
}

.inputContainer {
  width: 90%;
  margin: 15px 0;
  box-shadow: 0px 0px 5px #dadbe6;
  border-radius: 8px;
  position: relative;
}

.suggestions {
  position: absolute;
  width: 100%;
  z-index: 5;
  top: 120%;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 10px #dadbe6;
  overflow: hidden;
}

.suggestion {
  padding: 12px 5px;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  align-items: center;
  color: rgb(22, 33, 91);
  gap: 10px;
}
.suggestion > div:first-child {
  display: flex;
  justify-content: center;
  align-items: center;
}
.suggestion > div:not(:first-child) {
  min-width: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.suggestion:hover {
  background-color: rgba(22, 33, 91, 0.2);
}

.continueSection {
  font-size: 2rem;
  margin: 1em auto 0;
  width: 20.96em;
  width: 100%;
}

.continueButton {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    #2ecc71;
  border-radius: 11px;
  width: 100%;
  text-align: center;
  outline: none;
  border: none;
  color: #fff;
  font-weight: bold;
}
