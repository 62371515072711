/* @tailwind base; */
@tailwind components;
@tailwind utilities;

/* autoprefixer grid: autoplace */

/* @import-normalize; */

/* bring in normalize.css styles */

body {
  zoom: 90%;

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F7F8FC;
}

::-webkit-scrollbar {
  width: 0;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.canvasjs-chart-canvas {
  height: 223px;
}

.toggle-bg:after {
  content: '';
  @apply absolute top-0.5 left-0.5 bg-white border border-gray-300 rounded-full h-5 w-5 transition shadow-sm;
}
input:checked + .toggle-bg:after {
  transform: translateX(100%);
  @apply border-white;
}
input:checked + .toggle-bg {
  @apply bg-green-600 border-green-600;
}
