.MuiInput-underline:before,
.MuiInput-underline:after {
  border-bottom: 1px solid #1f299c !important;
}

.MuiInputBase-input {
  color: #1f299c !important;
  font-size: 20px !important;
  font-weight: 600 !important;
}

.MuiPickersToolbar-toolbar {
  background-color: #1f299c !important;
}

.MuiTypography-colorPrimary {
  color: #1f299c !important;
}

.MuiLinearProgress-colorPrimary {
  background-color: #f25929 !important;
}

.MuiLinearProgress-barColorPrimary {
  background-color: #0373ff !important;
}

.MuiLinearProgress-root {
  height: 40px !important;
  border-radius: 6px;
}
